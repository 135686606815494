function headerSearch() {
  document.addEventListener("DOMContentLoaded", function () {
    const searchContainer = document.querySelector(
      ".header-search-container, .mobile-header-search-container"
    );
    const searchInput = searchContainer.querySelector(".search-input, .search");
    const searchOverlay = document.querySelector(
      ".header-search-overlay, .mobile-header-search-overlay"
    );
    const closeSearchBtn = searchContainer.querySelector(".btn-close-search");
    const body = document.body;
    const rect = searchContainer.getBoundingClientRect();
    const top = rect.top + window.scrollY;
    const right = window.innerWidth - rect.right; // Step 2: Correct calculation for 'right'
    const width = rect.width;

    function closeSearch() {
      // Remove class from body
      body.classList.remove("search-active");
      $("#header-search-results, .mobile-header-search-overlay").removeClass(
        "active"
      );
      $(".mobile-header-search-container").removeClass("toggle-search");
      // Reset the position of the input only if 'search-active' class is not present
      if (!body.classList.contains("search-active")) {
        searchContainer.style.position = "absolute";
        searchContainer.style.top = `${top}px`;
        searchContainer.style.right = `${right}px`;
        searchContainer.style.width = `${width}px`;
      }

      $("#search").val("");
      $("#header-search-results, #mobile-header-search-results").empty();

      searchInput.blur();
    }

    searchContainer.addEventListener("click", function (e) {
      e.stopPropagation(); // Prevent the event from bubbling up to the body

      // Only apply manual positioning if 'search-active' class is not present
      if (
        !body.classList.contains("search-active") &&
        !e.target.closest(".btn-close-search")
      ) {
        // Set the position to fixed with the same coordinates
        searchContainer.style.position = "absolute";
        body.classList.add("search-active");
        searchContainer.style.top = `${top}px`;
        searchContainer.style.right = `${right}px`;
        searchContainer.style.width = `${width}px`;

        // Focus the input
        searchInput.focus();
      }
    });


    let currentIndex = -1; // Keeps track of the current highlighted index
    function updateHighlight(results) {
        // Remove the highlight class from all results
        results.forEach(item => item.classList.remove('highlight'));

        // Add the highlight class to the current result
        if (currentIndex >= 0 && currentIndex < results.length) {

            results[currentIndex].classList.add('highlight');

            // Optionally, scroll the highlighted item into view
            results[currentIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            });
        }
    }

    // When the body has the search-active class, listen for escape key to close the search
    document.addEventListener("keydown", function (e) {
       
        const resultsContainer = document.getElementById('header-search-results');
        

        const results = resultsContainer.querySelectorAll('.header-search-results-item');

            // Down arrow key (40)
            if (e.keyCode === 40) {
                e.preventDefault(); // Prevent the default action (scrolling)

                if (currentIndex < results.length - 1) {
                    currentIndex++;
                    updateHighlight(results);
                }
            }

            if (e.keyCode === 38) {
                e.preventDefault(); // Prevent the default action (scrolling)

                if (currentIndex > 0) {
                    currentIndex--;
                    updateHighlight(results);
                }
            }

            // Enter key (13)
            if (e.keyCode === 13 && currentIndex >= 0) {
                e.preventDefault(); // Prevent the form from submitting
                results[currentIndex].click(); // Simulate click on the highlighted result
            }

        if (body.classList.contains("search-active") && e.key === "Escape") {
            closeSearch();
        } 
    });

    closeSearchBtn.addEventListener("click", function () {
      closeSearch();
    });

    searchOverlay.addEventListener("click", function () {
      closeSearch();
    });

    $(".mobile-header-search-overlay").on("click", function () {
      closeSearch();
    });
  });

  $("#search, #mobile-header-search-input, #search-page-search").on("input", function () {
    updateSearchResults();
  });


  $(document).on("change", "#search-type", function () {
    updateSearchResults();
  });

  function updateSearchResults() {
    var query = $("#search").val() || $("#mobile-header-search-input").val();
    var type = $("#search-type").val() || $("#CDrop_mobile .custom-select-input").text().trim();

    if (query.length > 0) {
        $.ajax({
            url: "/autocompleteHome",
            method: "GET",
            data: { term: query, type: type },
            dataType: "json",
            success: function (response) {
                    response = Array.isArray(response) ? response : Object.values(response);

                // Sort the response by category, handling null values
                response.sort((a, b) => {
                    const categoryA = a.category || '';
                    const categoryB = b.category || '';
                    return categoryA.localeCompare(categoryB);
                });



                // Group items by category
                const groupedByCategory = response.reduce((acc, item) => {
                    acc[item.category] = acc[item.category] || [];
                    acc[item.category].push(item);
                    return acc;
                }, {});

                // Initialize an empty string to hold your HTML
                let htmlResults = "";

                // Iterate over each category to construct HTML
                Object.keys(groupedByCategory).forEach((category) => {
                    const categorySlug = toSlug(category);

                    // View all link for each category that goes to the search results page with the category filter, search term, and type
                    htmlResults += `<div class="header-search-results-category">
                                    <div class="header-search-results-category-header">
                                        <a href="/search-results?category=${categorySlug}" class="header-search-results-category-view-all">View All</a>
                                        ${category}
                                    </div>`;

                    // Add each item within the category
                    groupedByCategory[category].forEach((item) => {

                        const itemSlug = item.slug ? item.slug : '';
                        const itemUrl = item.type === "category"
                            ? `/search-results?category=${item.name}&query=${query}&type=${type}`
                            : `${itemSlug}`;

                        htmlResults += `<a href="${itemUrl}" class="header-search-results-item">
                                        <div class="header-search-results-item-details">
                                            <div class="header-search-results-item-title">${item.name}</div>
                                        </div>
                                    </a>`;
                    });

                    // Close the category div
                    htmlResults += `</div>`;
                });

                // Check if there are results to show
                if (Object.keys(groupedByCategory).length > 0) {
                    $("#header-search-results").addClass("active");
                } else {
                    $("#header-search-results").removeClass("active");
                }

                // Insert the HTML into the DOM
                $("#header-search-results, #mobile-header-search-results").html(htmlResults);
            },
            error: function (xhr, status, error) {
                console.error("An error occurred:", status, error);
                // Optionally handle errors here
            }
        });
    } else {
        // If the query is empty, clear the results and remove the active class
        $("#header-search-results").html("").removeClass("active");
    }
}


  // Function to close search and clear results
  $("#search-close").on("click", function () {
    $("#search").val("");
    $("#header-search-results").empty().removeClass("active");
  });

  function toSlug(categoryName) {
    return categoryName
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
}

export default headerSearch;
